import qs from 'qs'

export const queryNewsletterPage = () => {
  const query = qs.stringify(
    {
      populate: {
        titles: true,
        labels: true,
        disclaimers: true,
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `newsletter-page?${query}`
}
