<template>
  <form :class="$style.root">
    <div :class="$style.container">
      <input
        :class="$style.input"
        :placeholder="$t('mailchimp.email')"
        v-model="email"
        type="email"
        autocomplete="off"
        @keyup.enter="subscribe"
      />
    </div>

    <div :class="$style.group">
      <div :class="$style.container">
        <input
          :class="$style.input"
          :placeholder="$t('mailchimp.firstName')"
          v-model="firstName"
          type="text"
          autocomplete="off"
          @keyup.enter="subscribe"
        />
      </div>
      <div :class="$style.container">
        <input
          :class="$style.input"
          :placeholder="$t('mailchimp.lastName')"
          v-model="lastName"
          type="text"
          autocomplete="off"
          @keyup.enter="subscribe"
        />
      </div>
    </div>

    <div :class="$style.container">
      <div :class="$style.selectContainer">
        <select :class="$style.input" v-model="language" id="country">
          <option value="" disabled selected>{{ $t('mailchimp.language') }}</option>
          <option value="English">English</option>
          <option value="Spanish">Español</option>
          <option value="Catalan">Català</option>
        </select>
        <SvgoTriangleDown :class="$style.selectSvg" />
      </div>
    </div>

    <LayoutPillComponent
      :class="$style.button"
      viewOption="primary"
      :disabled="!isValid ? '' : null"
      @click="subscribe"
    >
      {{ $t('mailchimp.subscribe') }}
    </LayoutPillComponent>

    <div v-if="res?.success" :class="$style.message">
      {{ $t('mailchimp.confirmation') }}
    </div>
    <div v-if="res?.error" :class="$style.message">
      {{ $t('mailchimp.error') }}
    </div>
    <div v-if="res?.signedUp" :class="$style.message">
      {{ $t('mailchimp.signedUp') }}
    </div>
  </form>
</template>

<script setup>
import isEmail from 'validator/lib/isEmail'
import isLength from 'validator/lib/isLength'
const email = ref('')
const firstName = ref('')
const lastName = ref('')
const language = ref('')

const res = ref(null)
watch(res, (val) => {
  if (val) {
    setTimeout(() => {
      res.value = null
    }, 10000)
  }
})

const isValid = computed(() => {
  return isEmail(email.value) && isLength(firstName.value, { min: 1 }) && isLength(lastName.value, { min: 1 }) && isLength(language.value, { min: 1 })
})

const subscribe = async (e) => {
  e.preventDefault()
  if (!isValid.value) return

  const { data, error } = await useFetch('/api/mailchimp', { params: { email: email.value, firstName: firstName.value, lastName: lastName.value, language: language.value } })
  res.value = data?.value
}
</script>

<style module lang=scss>
.root {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: var(--unit--spacer);
}

.group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: var(--unit--spacer);

  @media (max-width: $breakpoint-s) {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: var(--unit--spacer);
  }
}

.container {
  position: relative;
}

.label {
  composes: font-size-x-small from global;
  color: var(--color--black);
  margin-bottom: calc(var(--unit--spacer) / 2);
  display: block;
}

.label:before {
  content: '';
  font-size: var(--font-small--font-size);
  display: inline-block;
  padding-left: var(--padding--button--left);
}

.input {
  composes: reset-input font-size-small from global;
  -webkit-appearance: none;

  width: 100%;
  border: var(--stroke--default) solid var(--color--gray);
  outline: none;
  border-radius: 9999px;
  padding: var(--padding--button--top) var(--padding--button--right) var(--padding--button--bottom) var(--padding--button--left);

  color: var(--color--black);
  background-color: var(--color--white);
}

.input:focus {
  border: var(--stroke--default) solid var(--color--blue);
}

.input::placeholder {
  color: inherit;
}

.selectContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.selectSvg {
  width: var(--unit--horizontal);
  height: calc(var(--unit--horizontal) * .55);

  margin-left: calc(var(--unit--horizontal) * -2);
  margin-right: var(--unit--horizontal);

  stroke: var(--color--black);
  stroke-width: var(--stroke--default);
  fill: none;

  overflow: visible;
}

.button {
  margin-left: auto;
}

.message {
  composes: font-size-x-small from global;
  color: var(--color--blue);
}
</style>
