<template>
  <div
    :class="$style.root"
  >
    <div :class="$style.title">
      {{ label }}
    </div>

    <i18nLink
      :class="$style.button"
      @click="$emit('closeSelf')"
      :to="parentRoute"
    >
      <SvgoCross data-cross />
    </i18nLink>

    <SharedMailchimpComponent :class="$style.form" />

    <CoreTextComponent
      :class="$style.footer"
      v-if="disclaimer"
      :string="disclaimer"
    />
  </div>
</template>

<script setup>
const route = useRoute()

const props = defineProps({
  pageData: Object
})

const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.pageData?.data))
})

const label = computed(() => i18nGetLocalizedString(localizedData?.value?.labels))

const disclaimer = computed(() => {
  return i18nGetLocalizedString(localizedData?.value?.disclaimers)
})

const parentRoute = computed(() => {
  if (route?.matched?.length > 1) {
    return {
      path: route.matched[0].path,
      query: {
        category: route.query?.category
      }
    }
  } else {
    return {
      path: route.path,
      query: {
        category: route.query?.category
      }
    }
  }
})
</script>

<style module>
.root {
  max-width: 100%;
  width: var(--container--small);

  padding: calc(var(--unit--vertical) * 2) calc(var(--unit--horizontal) * 2);

  display: grid;
  grid-template-columns: 1fr min-content;
  grid-row-gap: calc(var(--unit--vertical) * 2);
  grid-column-gap: var(--unit--spacer);
  align-items: start;
  grid-template-areas: 'title button' 'form form' 'footer footer';
}

.title {
  composes: font-size-large from global;
  grid-area: title;
}

.button {
  composes: reset-link button-round from global;
  grid-area: button;

  position: sticky;
  z-index: 1;
  top: var(--unit--vertical);
}

.form {
  margin-bottom: auto;
  grid-area: form;
}

.footer {
  composes: font-size-x-small from global;
  color: var(--color--gray--mid);

  grid-area: footer;
}
</style>
