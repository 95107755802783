<template>
  <div
    :class="$style.root"
    @keydown.esc="closeSelf"
    ref="rootElement"
    tabindex="0"
    :style="{
      '--color--background': backgroundColor,
    }"
    :data-backdrop-filter="isMounted && !slideOut ? '' : null"
  >
    <transition name="slide-right">
      <div
        :class="$style.container"
        v-show="isMounted && !slideOut"
        ref="containerElement"
      >
        <div
          :class="$style.content"
          ref="contentElement"
          :data-min-height="minHeight ? '' : null"
        >
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
const localePath = useLocalePath()
const router = useRouter()

const rootElement = ref(null)
const containerElement = ref(null)
const contentElement = ref(null)

const isMounted = ref(false)

const props = defineProps({
  parentRoute: {
    type: [String, Object],
    default: true,
  },
  minHeight: {
    type: Boolean,
    default: true,
  },
  backgroundColor: {
    type: String,
    default: 'var(--color--white)',
  },
  slideOut: {
    type: Boolean,
    default: false,
  }
})

useMutationObserver(contentElement, (mutations) => {
  for (const mutation of mutations) {
    rootElement.value?.scrollTo({ top: 0, behavior: 'smooth'})
  }
}, {
  childList: true
})

const closeSelf = () => {
  router?.push(localePath(props?.parentRoute))
}

onClickOutside(contentElement, (e) => {
  if ( e.target !== rootElement.value && e.target !== containerElement.value ) return
  closeSelf()
}, { ignore: ['a', 'button', 'menu'] })

useHead({
  htmlAttrs: [
    { 'data-overflow-hidden': '' },
  ]
})

onMounted(() => {
  if (process.client) {
    setTimeout(() => {
      isMounted.value = true
      rootElement.value?.focus()
    }, 25)
  }
})

onBeforeUnmount(() => {
  if (process.client) {
    document.documentElement.removeAttribute('data-overflow-hidden', '')
  }
})
</script>

<style module>
.root {
  position: fixed;
  z-index: var(--z-index--modal);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: flex-end;
  flex-direction: column;

  overflow: scroll;

  transition: backdrop-filter var(--transition--default);
  backdrop-filter: none;
}

.root[data-backdrop-filter] {
  backdrop-filter: var(--backdrop-filter--brightness--dark);
}

.root:focus {
  outline: none;
}

.container {
  padding-top: var(--site-header--height, calc(var(--unit--vertical) * 2));
  margin-top: auto;
}

.content {
  background-color: var(--color--background);
  border-top-left-radius: var(--border-radius--default);

  box-shadow: 0 0 var(--unit--vertical) rgba(0,0,0,0.1);

  display: flex;
  align-items: flex-end;
  flex-direction: column;

  max-width: 100vw;
}

.content[data-min-height] {
  min-height: calc(var(--100dvh) - var(--site-header--height, (var(--unit--vertical) * 2)));
}

@keyframes backdropAnimation {
  from {
    backdrop-filter: none;
  }
  to {
    backdrop-filter: var(--backdrop-filter--brightness--dark);
  }
}
</style>
